import { useEffect } from "react";
import { Bar, Pie } from "react-chartjs-2";

export const PrimeiraPagina = ({ dashboardData }) => {
  const groupByDate = dashboardData?.reduce((acc, item) => {
    const date = new Date(item.created_at).toLocaleDateString(); // Extrai apenas a data
    acc[date] = (acc[date] || 0) + 1; // Incrementa a contagem
    return acc;
  }, {});

  const labels = Object.keys(groupByDate); // ["2024-12-01", "2024-12-02"]
  const values = Object.values(groupByDate); // [2, 1]

  const data = {
    labels,
    datasets: [
      {
        label: "Quantidade por Data",
        data: values,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",

    plugins: {
      legent: { display: true },
      title: { display: true, text: "Quantidade de Pedidos" },
    },
    scales: {
      x: { title: { display: true, text: "Data" } },
      y: { title: { display: true, text: "Quantidade" }, beginAtZero: true },
    },
  };

  const groupByCategory = dashboardData?.reduce((acc, item) => {
    const category = item.product_category_name;
    acc[category] = (acc[category] || 0) + 1; // Incrementa a contagem
    return acc;
  }, {});

  const labelsCategoria = Object.keys(groupByCategory);
  const valuesCategoria = Object.values(groupByCategory);

  const dataCategory = {
    labels: labelsCategoria,
    datasets: [
      {
        label: "Total por categoria",
        data: valuesCategoria,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const optionsCategory = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",

    plugins: {
      legent: { display: true },
      title: { display: true, text: "Total por categoria" },
    },
    scales: {
      x: { title: { display: true, text: "Quantidade" }, beginAtZero: true },
      y: { title: { display: true, text: "Categoria" } },
    },
  };

  const groupByStatus = dashboardData?.reduce((acc, item) => {
    const status_name = item.status_name;
    acc[status_name] = (acc[status_name] || 0) + 1; // Incrementa a contagem
    return acc;
  }, {});

  const labelsStatus = Object.keys(groupByStatus);
  const valuesStatus = Object.values(groupByStatus);

  const dataStatus = {
    labels: labelsStatus,
    datasets: [
      {
        label: "Total por status",
        data: valuesStatus,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const optionsStatus = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",

    plugins: {
      legent: { display: true },
      title: { display: true, text: "Total por status" },
    },
    scales: {
      x: { title: { display: true, text: "Quantidade" }, beginAtZero: true },
      y: { title: { display: true, text: "Status" } },
    },
  };

  const groupByTipo = dashboardData?.reduce((acc, item) => {
    const Tipo_name = item.order_type_description;
    acc[Tipo_name] = (acc[Tipo_name] || 0) + 1; // Incrementa a contagem
    return acc;
  }, {});

  const labelsTipo = Object.keys(groupByTipo);
  const valuesTipo = Object.values(groupByTipo);

  const dataTipo = {
    labels: labelsTipo,
    datasets: [
      {
        label: "Total por Tipo",
        data: valuesTipo,
        borderWidth: 1,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)", // Cor para a primeira fatia
          "rgba(54, 162, 235, 0.5)", // Cor para a segunda fatia
          "rgba(255, 206, 86, 0.5)", // Cor para a terceira fatia
          "rgba(75, 192, 192, 0.5)", // Cor para a quarta fatia
          "rgba(153, 102, 255, 0.5)", // Cor para a quinta fatia
          "rgba(255, 159, 64, 0.5)", // Cor para a sexta fatia
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)", // Borda para a primeira fatia
          "rgba(54, 162, 235, 1)", // Borda para a segunda fatia
          "rgba(255, 206, 86, 1)", // Borda para a terceira fatia
          "rgba(75, 192, 192, 1)", // Borda para a quarta fatia
          "rgba(153, 102, 255, 1)", // Borda para a quinta fatia
          "rgba(255, 159, 64, 1)", // Borda para a sexta fatia
        ],
      },
    ],
  };

  const optionsTipo = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",

    plugins: {
      legent: { display: true },
      title: { display: true, text: "Total por Tipo" },
    },
    scales: {
      y: { title: { display: true, text: "Quantidade" }, beginAtZero: true },
      x: { title: { display: true, text: "Tipo" } },
    },
  };

  return (
    <>
      <div style={{ width: "100%", height: "250px" }}>
        <Bar data={data} options={options} />
      </div>

      <div style={{ width: "33%", height: "450px" }}>
        <Bar data={dataCategory} options={optionsCategory} />
      </div>

      <div style={{ width: "33%", height: "450px" }}>
        <Bar data={dataStatus} options={optionsStatus} />
      </div>

      <div style={{ width: "33%", height: "450px" }}>
        <Pie data={dataTipo} options={optionsTipo} />
      </div>
    </>
  );
};
