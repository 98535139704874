import { Bar } from "react-chartjs-2";

export const SegundaPagina = ({dashboardData}) => {

  


  const groupByMaterial = dashboardData.reduce((acc, item) => {
    const Material_name = item.material_code;
    acc[Material_name] = (acc[Material_name] || 0) + 1; // Incrementa a contagem
    return acc;
  }, {})

  const labelsMaterial = Object.keys(groupByMaterial)
  const valuesMaterial = Object.values(groupByMaterial)

  const dataMaterial = {
    labels: labelsMaterial,
    datasets: [{
      label: "Total por Material",
      data: valuesMaterial,
      borderWidth: 1,
      backgroundColor: [

        'rgba(75, 192, 192, 0.5)', // Cor para a quarta fatia
      ],
      borderColor: [
        'rgba(75, 192, 192, 0.1)', // Cor para a quarta fatia
      ]
    }]
  }

  const optionsMaterial = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',

    plugins: {
      legent: { display: true },
      title: { display: true, text: 'Total por Material' }
    },
    scales: {
      y: { title: { display: true, text: 'Quantidade' }, beginAtZero: true },
      x: { title: { display: true, text: 'Material' } },
    }

  }


  const groupByMaterialColor = dashboardData.reduce((acc, item) => {
    const MaterialColor_name = item.material_color_code;
    acc[MaterialColor_name] = (acc[MaterialColor_name] || 0) + 1; // Incrementa a contagem
    return acc;
  }, {})

  const labelsMaterialColor = Object.keys(groupByMaterialColor)
  const valuesMaterialColor = Object.values(groupByMaterialColor)

  const dataMaterialColor = {
    labels: labelsMaterialColor,
    datasets: [{
      label: "Total por MaterialColor",
      data: valuesMaterialColor,
      borderWidth: 1,
      backgroundColor: [

        'rgba(75, 192, 192, 0.5)', // Cor para a quarta fatia
      ],
      borderColor: [
        'rgba(75, 192, 192, 0.1)', // Cor para a quarta fatia
      ]
    }]
  }

  const optionsMaterialColor = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',

    plugins: {
      legent: { display: true },
      title: { display: true, text: 'Total por Cor' }
    },
    scales: {
      y: { title: { display: true, text: 'Quantidade' }, beginAtZero: true },
      x: { title: { display: true, text: 'Cor' } },
    }

  }

  const groupByCliente = dashboardData.reduce((acc, item) => {
    const Cliente_name = item.client_name;
    acc[Cliente_name] = (acc[Cliente_name] || 0) + 1; // Incrementa a contagem
    return acc;
  }, {})



  const labelsCliente = Object.keys(groupByCliente)
  const valuesCliente = Object.values(groupByCliente)

  const dataCliente = {
    labels: labelsCliente,
    datasets: [{
      label: "Total por Cliente",
      data: valuesCliente,
      borderWidth: 1,
      backgroundColor: [

        'rgba(75, 192, 192, 0.5)', // Cor para a quarta fatia
      ],
      borderColor: [
        'rgba(75, 192, 192, 0.1)', // Cor para a quarta fatia
      ]
    }]
  }

  const optionsCliente = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',

    plugins: {
      legent: { display: true },
      title: { display: true, text: 'Total por Cor' }
    },
    scales: {
      y: { title: { display: true, text: 'Quantidade' }, beginAtZero: true },
      x: { title: { display: true, text: 'Cor' } },
    }

  }


  
  return (
    <>
      <div style={{ width: "33%", height: "450px" }}>
        <Bar data={dataMaterial} options={optionsMaterial} />
      </div>

      <div style={{ width: "66%", height: "450px" }}>
        <Bar data={dataMaterialColor} options={optionsMaterialColor} />
      </div>

      <div style={{ width: "100%", height: "450px" }}>
        <Bar data={dataCliente} options={optionsCliente} />
      </div>
    </>
  );
};
