import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

export const TerceiraPagina = ({dashboardData}) => {
  const groupByProduto = dashboardData.reduce((acc, item) => {
    const produto = item.part_code;
    acc[produto] = (acc[produto] || 0) + 1; // Incrementa a contagem
    return acc;
  }, {})

  const [rankingLabelsProduto, setRankingLabelsProduto] = useState([]);
  const [rankingValuesProduto, setRankingValuesProduto] = useState([]);

  useEffect(() => {
    const newArrayProdutos =
      Object.entries(groupByProduto)
        .map(([item, value]) => ({ produto: item, quantidade: value }))
        .sort((a, b) => { if (a.produto != null) return b.quantidade - a.quantidade })
        .slice(0, 10).reduce((acc, item) => {
          acc[item.produto] = item.quantidade;
          return acc;
        }, {});

    setRankingLabelsProduto(Object.keys(newArrayProdutos));
    setRankingValuesProduto(Object.values(newArrayProdutos));

  }, [groupByProduto])




  const [rankingNegativoLabelsProduto, setRankingNegativoLabelsProduto] = useState([]);
  const [rankingNegativoValuesProduto, setRankingNegativoValuesProduto] = useState([]);

  useEffect(() => {
    const newArrayProdutos =
      Object.entries(groupByProduto)
        .map(([item, value]) => ({ produto: item, quantidade: value }))
        .sort((a, b) => a.quantidade - b.quantidade)
        .slice(0, 10).reduce((acc, item) => {
          acc[item.produto] = item.quantidade;
          return acc;
        }, {});

    setRankingNegativoLabelsProduto(Object.keys(newArrayProdutos));
    setRankingNegativoValuesProduto(Object.values(newArrayProdutos));

  }, [groupByProduto])

  const dataRankingMaisProduzidos = {
    labels: rankingLabelsProduto,
    datasets: [{
      label: "Produtos mais produzidos",
      data: rankingValuesProduto,
      borderWidth: 1,
      backgroundColor: [

        'rgba(75, 192, 192, 0.5)', // Cor para a quarta fatia
      ],
      borderColor: [
        'rgba(75, 192, 192, 0.1)', // Cor para a quarta fatia
      ]
    }]
  }

  const optionsRankingMaisProduzidos = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',

    plugins: {
      legent: { display: true },
      title: { display: true, text: 'Mais produzidos' }
    },
    scales: {
      y: { title: { display: true, text: 'Quantidade' }, beginAtZero: true },
      x: { title: { display: true, text: 'Cor' } },
    }

  }


  const dataRankingMenosProduzidos = {
    labels: rankingNegativoLabelsProduto,
    datasets: [{
      label: "Produtos menos produzidos",
      data: rankingNegativoValuesProduto,
      borderWidth: 1,
      backgroundColor: [

        'rgba(75, 192, 192, 0.5)', // Cor para a quarta fatia
      ],
      borderColor: [
        'rgba(75, 192, 192, 0.1)', // Cor para a quarta fatia
      ]
    }]
  }

  const optionsRankingMenosProduzidos = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',

    plugins: {
      legent: { display: true },
      title: { display: true, text: 'Produtos menos produzidos' }
    },
    scales: {
      y: { title: { display: true, text: 'Quantidade' }, beginAtZero: true },
      x: { title: { display: true, text: 'Cor' } },
    }

  }
  return (
    <>
      <div style={{ width: "50%", height: "450px" }}>
        <Bar
          data={dataRankingMaisProduzidos}
          options={optionsRankingMaisProduzidos}
        />
      </div>

      <div style={{ width: "50%", height: "450px" }}>
        <Bar
          data={dataRankingMenosProduzidos}
          options={optionsRankingMenosProduzidos}
        />
      </div>
    </>
  );
};
